
export const productListData = [
   {
      "id": "aula-f65",
      "title": "AULA F65",
      "desc": "The EPOMAKER x AULA F65 is the latest in the AULA F Series, offering a 65% compact layout with 67 keys..",
      "className": "", 
      "img": require("../../imgs/Products/aula-f65.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-aula-f65?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/4hJAI5v",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/q87jewXwJTA?si=eMY5qWJxVPI5slgu" 
   },
   {
      "id": "gmk-hanami-dango",
      "title": "GMK Hanami Dango Keycap Set",
      "desc": "The Hanami Dango keyboard, inspired by the delicious and colorful Japanese sweet, is an epitome of tasteful design...",
      "className": "", 
      "img": require("../../imgs/Products/gmk-hanami-dango.png"),
      "links": { 
         "url":"https://apos.audio/products/gmk-hanami-dango-keycaps",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/boHM6pEdWK4?si=9lGgrwu6AjEFQLu-" 
   },
   {
      "id": "mfish-4-in-1",
      "title": "MFISH 4-in-1 Multi USB Charging Cable",
      "desc": "MAX 240W Fast Charging/Charger Cord with USB A+Type C for iPhone 16/15 14 13 iPad Samsung Galaxy MacBook iPad Tablets and More..",
      "className": "", 
      "img": require("../../imgs/Products/mfish-4-in-1.png"),
      "links": { 
         "url":"https://amzn.to/48vFdfC",
         "amazon": "https://amzn.to/48vFdfC",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/lbsfjB4lU6U" 
   },
   {
      "id": "corsair-virtuoso-pro",
      "title": "Corsair Virtuoso Pro",
      "desc": "Wired Open Back Gaming Headset - Detachable Uni-Directional Microphone..",
      "className": "", 
      "img": require("../../imgs/Products/corsair-virtuoso-pro.png"),
      "links": { 
         "url":"https://amzn.to/3UwbSMk",
         "amazon": "https://amzn.to/3UwbSMk",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/KQ861fHMIBI?si=rcu0lhDga9d8DiXA" 
   },
   {
      "id": "corsair-k65-plus",
      "title": "Corsair K65 Plus",
      "desc": "75% RGB Mechanical Gaming Keyboard – Pre-Lubricated MLX Red Linear Switches..",
      "className": "", 
      "img": require("../../imgs/Products/corsair-k65-plus.png"),
      "links": { 
         "url":"https://amzn.to/3Yks7gB",
         "amazon": "https://amzn.to/3Yks7gB",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/FMsPSVPN8dM" 
   },
   {
      "id": "tonor-t90-mic-arm",
      "title": "Tonor T90 Mic Arm",
      "desc": "RGB Boom Arm, TONOR Adjustable Mic Stand with RGB Light..",
      "className": "", 
      "img": require("../../imgs/Products/tonor-t90-mic-arm.png"),
      "links": { 
         "url":"https://amzn.to/3NEEZZQ",
         "amazon": "https://amzn.to/3NEEZZQ",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/jYojnIwzrsQ" 
   },
   {
      "id": "akko-jin01-santorini",
      "title": "Akko JIN01 Santorini",
      "desc": "Aluminum Case, 68-key Gasket Structure Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/akko-jin01-santorini.png"),
      "links": { 
         "url":"https://en.akkogear.com/product/jin01-santorini-mechanical-keyboard/?ref=66437",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/-LJcIrseHSc?si=cJmC4bei1macUNws" 
   },
   {
      "id": "akko-rosewood",
      "title": "Akko Rosewood",
      "desc": "Rosewood is born with the resolution of making a nice low-pitch switch..",
      "className": "", 
      "img": require("../../imgs/Products/akko-rosewood.png"),
      "links": { 
         "url":"https://en.akkogear.com/product/akko-rosewood-switch/?ref=66437",
         "amazon": "https://amzn.to/3BV3vn2",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/-LJcIrseHSc?si=cJmC4bei1macUNws"
   },
   {
      "id": "apple-airtag",
      "title": "Apple AirTag 4 Pack",
      "desc": "Keep track of and find your items alongside friends and devices in the Find My app..",
      "className": "", 
      "img": require("../../imgs/Products/apple-airtag.png"),
      "links": { 
         "url":"https://amzn.to/3A0GRsY",
         "amazon": "https://amzn.to/3A0GRsY",
         "aliexpress": "",
      },
      "type": "all, other, setup", 
      "preview": "https://www.youtube.com/embed/5hpbpDN8DPY" 
   },
   {
      "id": "ulanzi-ma35",
      "title": "Ulanzi MA35 Phone Camera",
      "desc": "ULANZI Selfie Phone Tripod Mount MA35, 2-in-1 Magnetic & Phone Clip Camera Grip Handle Holder..",
      "className": "", 
      "img": require("../../imgs/Products/ulanzi-ma35.png"),
      "links": { 
         "url":"https://amzn.to/4gOkTcZ",
         "amazon": "https://amzn.to/4gOkTcZ",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/P3eKPGZSq7Y" 
   },
   {
      "id": "ge-cync-smart-plug",
      "title": "GE CYNC Smart Plug",
      "desc": "Indoor Bluetooth and Wi-Fi Outlet Socket, Works with Alexa and Google ..",
      "className": "", 
      "img": require("../../imgs/Products/ge-cync-smart-plug.png"),
      "links": { 
         "url":"https://amzn.to/3BlSOcU",
         "amazon": "https://amzn.to/3BlSOcU",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/yfSDU_7qaWw" 
   },
   {
      "id": "aula-f81",
      "title": "AULA F81",
      "desc": "75% Wireless Gasket mounted Hot-Swappable Transparent Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/aula-f81.png"),
      "links": { 
         "url":"https://aulagear.com/products/aula-f81?sca_ref=7077216.88tO37qvRC",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/37pThavM90Q" 
   },
   {
      "id": "ge-cync-light-bulbs",
      "title": "GE CYNC A19 Smart LED Light Bulb",
      "desc": "Color Changing Room Décor, Bluetooth and WiFi Light Bulbs, 60W Equivalent..",
      "className": "", 
      "img": require("../../imgs/Products/ge-cync-light-bulbs.png"),
      "links": { 
         "url":"https://amzn.to/3TFJ3wt",
         "amazon": "https://amzn.to/3TFJ3wt",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/7Q2Sa4RELGg" 
   },
   {
      "id": "epomaker-ms68",
      "title": "EPOMAKER MS68",
      "desc": "Wireless Gaming Keyboard with 0.85” Screen, CNC Aluminum Alloy Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-ms68.png"),
      "links": { 
         "url":"https://amzn.to/3ZvWT8l",
         "amazon": "https://amzn.to/3ZvWT8l",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/tvY_ZmztY8E?si=zxETB7JZ75PM_Cjx" 
   },
   {
      "id": "etenwolf-p300",
      "title": "Etenwolf Ball Pump",
      "desc": "Ball Pump Inflation & Deflation 2-17PSI, Pump with Precise Pressure Gauge, Rechargeable Air Pump for Balls..",
      "className": "", 
      "img": require("../../imgs/Products/etenwolf-p300.png"),
      "links": { 
         "url":"https://amzn.to/4deEr7v",
         "amazon": "https://amzn.to/4deEr7v",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://youtube.com/embed/ac6u3lw_2zE" 
   },
   {
      "id": "xvx-k75-pro",
      "title": "XVX K75 Pro",
      "desc": "CNC Aluminum Mechanical Keyboard Hot Swap Switchs Flex Cuts PCB With PBT Keycaps..",
      "className": "", 
      "img": require("../../imgs/Products/xvx-k75-pro.png"),
      "links": { 
         "url":"https://www.xvxchannel.com/products/xvx-k75pro?variant=46547838927085&sca_ref=6468758.FBtpMSiLWg",
         "amazon": "https://amzn.to/4d1M4hA",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/EAdYheBnd88?si=Ujm3crSmDqYCQVUv" 
   },
   {
      "id": "anker-magsafe",
      "title": "Anker Magsafe Portable",
      "desc": "Anker Magnetic Battery, 5,000mAh Foldable Magnetic Wireless Portable Charger with Stand iPhone Portable..",
      "className": "", 
      "img": require("../../imgs/Products/anker-magsafe.png"),
      "links": { 
         "url":"https://amzn.to/47gwhKt",
         "amazon": "https://amzn.to/47gwhKt",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": ""
   },
   {
      "id": "domikey-astronaut",
      "title": "Domikey Astronaut",
      "desc": "SMD Linear Switch 48g 58g Switches for Gaming Mechanical keyboard mx stem Nylon POM Hand Lubed..",
      "className": "", 
      "img": require("../../imgs/Products/domikey-astronaut.jpg"),
      "links": { 
         "url":"https://amzn.to/3XgA99F",
         "amazon": "https://amzn.to/3XgA99F",
         "aliexpress": "https://www.aliexpress.us/item/3256805777975419.html",
      },
      "type": "all, switch", 
      "preview": ""
   },
   {
      "id": "ciy-asura",
      "title": "CIY Asura",
      "desc": "Pre Advanced Tactile Switch 50g for Gaming Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/ciy-asura.png"),
      "links": { 
         "url":"https://amzn.to/3XoLLHP",
         "amazon": "https://amzn.to/3XoLLHP",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": ""
   },
   {
      "id": "mercury-k1",
      "title": "GravaStar Mercury K1 - Use Code SULCE",
      "desc": "Use Code SULCE for 15% Off - GravaStar Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/mercury-k1.png"),
      "links": { 
         "url":"https://www.gravastar.com/products/mercury-k1-gradient-black",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/WsQffbPCAU0?si=CiZyQUygrp8UT0ed" 
   },
   {
      "id": "nos75",
      "title": "NuPhy Nos75 - Use Code SULCE",
      "desc": "Use Code SULCE for 10% Off - QMK/VIA Wireless Low Profile Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/nos75.png"),
      "links": { 
         "url":"https://nuphy.com/products/nos75-low-profile-custom-mechanical-keyboard?sca_ref=5278418.1KL4ifu5Zj",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/4SfpiSuBoqU?si=49koP7ICBAWKibdM" 
   },
   {
      "id": "gem80",
      "title": "NuPhy Gem80 - Use Code SULCE",
      "desc": "Use Code SULCE for 10% Off - QMK/VIA Wireless Custom Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/gem80.png"),
      "links": { 
         "url":"https://nuphy.com/products/gem80?sca_ref=5278418.1KL4ifu5Zj",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard, highlight", 
      "preview": "https://www.youtube.com/embed/8Pkydtnn1LU?si=dxufWaAecKRyFotH" 
   },
   {
      "id": "dji-osmo-mobile-6",
      "title": "DJI Osmo Mobile 6",
      "desc": "3-Axis Phone Gimbal, Object Tracking, Built-in Extension Rod, Portable and Foldable..",
      "className": "", 
      "img": require("../../imgs/Products/dji-osmo-mobile-6.png"),
      "links": { 
         "url":"https://click.dji.com/AFvtqMdKd31zcfMBJ8n2Pg?pm=link",
         "amazon": "https://amzn.to/4dum4wf",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/f32PkHy9TUc"
   },
   {
      "id": "chilkey-nd75",
      "title": "Chilkey ND75",
      "desc": "ND75 Keyboard Assembled Edition..",
      "className": "", 
      "img": require("../../imgs/Products/chilkey-nd75.png"),
      "links": { 
         "url":"https://chilkey.com/r?id=478u8k",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/e8DVCGDoTis?si=UzUxyWDpXIi3IqsF"
   },
   {
      "id": "goblin-techkeys",
      "title": "Goblin Techkeys Keycaps - Use Code SULCE",
      "desc": "Use Code SULCE for 10% Off -  Custom Keycaps",
      "className": "", 
      "img": require("../../imgs/Products/goblin-techkeys.png"),
      "links": { 
         "url":"https://goblintechkeys.com/SULCE",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/M3ZJh3Dd150?si=W9KkywiHuv3wpKHa"
   },
   {
      "id": "mchose-gx87",
      "title": "MCHOSE GX87",
      "desc": "MCHOSE GX87 Mechanical Aluminum TKL Quick Release Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/mchose-gx87.jpg"),
      "links": { 
         "url":"https://goaff.pro/escgjp",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard, highlight", 
      "preview": "https://www.youtube.com/embed/1H8ZXjS2xJk?si=guZim84kJVto3Yog"
   },
   {
      "id": "elgato-lp-mic-arm",
      "title": "Elgato LP Mic Arm",
      "desc": "Premium Low Profile Microphone with Cable Management Channels, Desk Clamp..",
      "className": "", 
      "img": require("../../imgs/Products/elgato-lp-mic-arm.png"),
      "links": { 
         "url":"https://amzn.to/4cKacWy",
         "amazon": "https://amzn.to/4cKacWy",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "" 
   },
   {
      "id": "cerakey-ceramic-v2",
      "title": "CERAMIC KEYCAP SET V2",
      "desc": "A full 114 keys ceramic keycap set with color & legend options, compatible with Cherry MX switches...",
      "className": "", 
      "img": require("../../imgs/Products/cerakey-ceramic-v2.png"),
      "links": { 
         "url":"https://www.cerakey.com/products/full-set-v2?variant=41532871377035",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/kxDTgxN9RoE?si=gLwefk2G8tDckJ6t" 
   },
   {
      "id": "discord-tkl",
      "title": "Discord TKL",
      "desc": "Kono 87 Discord TKL Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/discord-tkl.png"),
      "links": { 
         "url":"https://apos.audio/products/discord-tkl-mechanical-keyboard",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/qlInnm80xQ4?si=h0URNVXpnLf8nA_j"
   },
   {
      "id": "maono-pd100x",
      "title": "Maono PD100X Mic",
      "desc": "MAONO PD100X USB/XLR Dynamic Microphone For Game Streamers..",
      "className": "", 
      "img": require("../../imgs/Products/maono-pd100x.png"),
      "links": { 
         "url":"https://www.maono.com/products/maono-pd100x-usb-xlr-dynamic-microphone-for-game-streamers?ref=bybxlnnw",
         "amazon": "https://amzn.to/3LszNXT",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/Zerg50-k1wg" 
   },
   {
      "id": "maonocaster-g1neo",
      "title": "Maonocaster G1 NEO",
      "desc": "Maonocaster G1 NEO Audio Mixer For Game Streamer..",
      "className": "", 
      "img": require("../../imgs/Products/maonocaster-g1neo.png"),
      "links": { 
         "url":"https://www.maono.com/products/maonocaster-amg1-neo-audio-mixer-for-game-streaming?ref=bybxlnnw",
         "amazon": "https://amzn.to/3Y24Rpg",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/Zerg50-k1wg" 
   },
   {
      "id": "monsgeek-m1v3",
      "title": "Monsgeek M1 V3",
      "desc": "Akko MonsGeek M1 V3 QMK/VIA International Version is once again aimed at enthusiasts..",
      "className": "", 
      "img": require("../../imgs/Products/monsgeek-m1v3.png"),
      "links": { 
         "url":"https://www.monsgeek.com/product/m1-v3/?ref=sulcetech",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/iVoAj3pzRoU?si=-orn6lhykM-ChX7l"
   },
   {
      "id": "akko-creamy-purple-pro",
      "title": "Akko V3 Creamy Purple Pro",
      "desc": "Akko Creamy Purple is signature for its strong bump at very top, yet extremely short total travel of 3.0mm..",
      "className": "", 
      "img": require("../../imgs/Products/akko-creamy-purple-pro.png"),
      "links": { 
         "url":"https://en.akkogear.com/product/akko-v3-creamy-purple-pro-switch/?ref=66437",
         "amazon": "https://amzn.to/4cVcnGj",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/iVoAj3pzRoU?si=l89pecrReqATqwwu&amp;start=390"
   },
   {
      "id": "akko-creamy-blue-pro",
      "title": "Akko V3 Creamy Blue Pro",
      "desc": "Creamy Blue Pro is an upgraded version of Creamy Blue..",
      "className": "", 
      "img": require("../../imgs/Products/akko-creamy-blue-pro.png"),
      "links": { 
         "url":"https://en.akkogear.com/product/akko-v3-cream-blue-pro-switch-45pcs/?ref=66437",
         "amazon": "https://amzn.to/4fatZ2c",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": ""
   },
   {
      "id": "ws-morandi",
      "title": "WS Morandi",
      "desc": "Morandi Linear Switch..",
      "className": "", 
      "img": require("../../imgs/Products/ws-morandi.png"),
      "links": { 
         "url":"https://amzn.to/4cIYqM3",
         "amazon": "https://amzn.to/4cIYqM3",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/Ni96Wd0SmAY" 
   },
   {
      "id": "tablet-monitor-holder",
      "title": "Tablet Stand Holder",
      "desc": "Monitor Stand Holder 15.6'',16'',Webcam Camera Ring Light Desk Mount with 360°Rotate Base,Fits for 4.7-16..",
      "className": "", 
      "img": require("../../imgs/Products/tablet-monitor-holder.png"),
      "links": { 
         "url":"https://amzn.to/4csBVuO",
         "amazon": "https://amzn.to/4csBVuO",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/fiTEZGzdcsA"
   },
   {
      "id": "epomaker-ek21x",
      "title": "Epomaker EK21-X VIA",
      "desc": "Gasket Aluminum Alloy Wireless Bluetooth/2.4GHz/Wired Type-C VIA Programmable Numpad..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-ek21x.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-ek21-x-via?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/3xGH9nt",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/CttUO0GwnRU?si=5A20o_gkcqU9MU59"
   },
   {
      "id": "gamakay-phoenix",
      "title": "Gamakay Phoenix - Use Code SULCE",
      "desc": "Use Code SULCE for 5% Off - GamaKay Mechanical Silent Switches - 35 Pcs/Pack Or 70 Pcs/Pack..",
      "className": "", 
      "img": require("../../imgs/Products/gamakay-phoenix.png"),
      "links": { 
         "url":"https://gamakay.com/products/gamakay-35pcs-pack-gamakay-switch-linear-mechanical-phoenix-crystal-bumblebee-switch-prelubricate-keyboard-switch-for-diy-mechanical-gaming-keyboards?ref=sulce&variant=41626582188221",
         "amazon": "https://amzn.to/3LnCudD",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/XDRWyn1EBBU?si=HCDEOulcxF96xJPn"
   },
   {
      "id": "gamakay-tk75-se",
      "title": "Gamakay TK75-SE - Use Code SULCE",
      "desc": "Use Code SULCE for 5% Off - GamaKay TK75 75% Mechanical Gaming Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/gamakay-tk75-se.png"),
      "links": { 
         "url":"https://gamakay.com/products/gamakay-tk75se-75-mechanical-keyboard?ref=sulce&variant=43722878451901",
         "amazon": "https://www.amazon.com/GK-GAMAKAY-TK75-SE-White-Outemu/dp/B0CX99Y75N?maas=maas_adg_1DCC91ADA83E3DC15ADD34189F6CE0E2_afap_abs&ref=sulce&ref_=aa_maas&tag=maas",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/tlWvswslS7s?si=58evKsx0K-h2TRL7" 
   },
   {
      "id": "womier-sk65",
      "title": "Womier SK65 - Use Code SULCE",
      "desc": "Use Code SULCE for 10% Off - WOMIER SK65 65% GASKET MOUNTED WIRELESS ALUMINUM KEYBOARD WITH KNOB..",
      "className": "", 
      "img": require("../../imgs/Products/womier-sk65.png"),
      "links": { 
         "url":"https://womierkeyboard.com/products/womier-sk65?sca_ref=6103295.YmVMPX9mS1",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard, highlight", 
      "preview": "https://www.youtube.com/embed/mdf9bHOpcfc?si=HF4fi2egYt533rgy" 
   },
   {
      "id": "aula-f75",
      "title": "AULA F75",
      "desc": "Budget 75% Gasket Wireless Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/aula-f75.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-aula-f75?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/3TltV7A",
         "aliexpress": "",
      },
      "type": "all, keyboard, highlight", 
      "preview": "https://www.youtube.com/embed/V7O1bW80TGE?si=ivvaW-1bmXmnkdt8" 
   },
   {
      "id": "keytok-smile-turn-to-90s",
      "title": "Keytok Smile Turn To 90's",
      "desc": "Keytok Smile Turn To 90's Dye-Sub PBT Keycaps..",
      "className": "", 
      "img": require("../../imgs/Products/keytok-smile-turn-to-90s.png"),
      "links": { 
         "url":"https://www.keytok.com/products/smile-turn-to-90s?sca_ref=6324503.nnyRHGu9h2",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/a2UDVTlcjI0" 
   },
   {
      "id": "epomaker-shadow-x",
      "title": "Epomaker Shadow-X",
      "desc": "70% Gasket Mount Hot Swappable 2.4Ghz/Bluetooth/USB-C Wired/Wireless Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-shadow-x.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-shadow-x?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/4e0rFeB",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/UcecVXsvhhI?si=E5iYAaYPhvAGlAYz" 
   },
   {
      "id": "epomaker-wisteria",
      "title": "EPOMAKER Wisteria",
      "desc": "EPOMAKER Wisteria Mechanical Keyboard Switches Set..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-wisteria.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-wisteria-switches-set?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/4c14DCk",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/UcecVXsvhhI?si=bMnGpxDiySmq1JwJ&amp;start=351" 
   },
   {
      "id": "arzopa-z1fc",
      "title": "Arzopa Z1FC 144hz Monitor",
      "desc": "Arzopa Z1FC 144Hz Portable Gaming Monitor | 16.1 Screen..",
      "className": "", 
      "img": require("../../imgs/Products/arzopa-z1fc.png"),
      "links": { 
         "url":"https://www.arzopa.com/products/z1fc-16-1-fhd-144hz-portable-monitor?ref=sulce",
         "amazon": "https://amzn.to/4e2ou5V",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/KMdUc2H7DjY" 
   },
   {
      "id": "aula-f87",
      "title": "AULA F87",
      "desc": "Budget TKL Gasket Wireless Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/aula-f87.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-aula-f87-pro?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "https://amzn.to/3UR3mXs",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/qjBheitm2gk?si=zZH6hWxqAObAHgRb" 
   },
   {
      "id": "stream-deck-mk2",
      "title": "Stream Deck MK.2",
      "desc": "Elgato Stream Deck MK.2 – Studio Controller, 15 macro keys, trigger actions in apps and software like OBS, Twitch, ​YouTube..",
      "className": "", 
      "img": require("../../imgs/Products/stream-deck-mk2.png"),
      "links": { 
         "url":"https://amzn.to/3wte4eG",
         "amazon": "https://amzn.to/3wte4eG",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/kPxD46jACBc" 
   },
   {
      "id": "outemu-panda",
      "title": "Outemu Panda",
      "desc": "Outemu Ocean Panda Switches Emerald Switch for Mechanical Keyboard Silent Clicky Linear Tactile Switch RGB Gaming..",
      "className": "", 
      "img": require("../../imgs/Products/outemu-panda.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256806293254658.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "" 
   },
   {
      "id": "womier-rose-whisper",
      "title": "Womier Rose Whisper - Use Code SULCE",
      "desc": "Use Code SULCE for 10% off - WOMIER FULL POM MINT BLIZZARD/ROSE WHISPER LINEAR SWITCH SET (35 PCS/BOX)..",
      "className": "", 
      "img": require("../../imgs/Products/womier-rose-whisper.png"),
      "links": { 
         "url":"https://womierkeyboard.com/products/womier-full-pom-switches?sca_ref=6103295.YmVMPX9mS1",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/TNkcuuRzSYE?si=JfsUUVPuIDbPKYsG" 
   },
   {
      "id": "th80-pro-v2",
      "title": "Epomaker TH80 Pro V2 VIA",
      "desc": "75% VIA Programmable Gasket-mounted Wired & Wireless Gaming Keyboard with Customizable Screen..",
      "className": "", 
      "img": require("../../imgs/Products/th80-pro-v2.png"),
      "links": { 
         "url":"https://epomaker.com/products/th80pro-v2-via?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/lJRNG_rPayU?si=F-IF0Dwt9OHUQrc4" 
   },
   {
      "id": "epomaker-peace-lily",
      "title": "Epomaker Peace Lily",
      "desc": "Original 100 Pieces of EPOMAKER Peace Lily Mechanical Keyboard Switch Set..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-peace-lily.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-peace-lily-switch-set?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/lJRNG_rPayU?si=oyJxe4M-nubhXeyB&amp;start=358" 
   },
   {
      "id": "epomaker-nude-rosa",
      "title": "Epomaker Nude Rosa Silent",
      "desc": "Original 35 Pieces of EPOMAKER Nude Rosa Silent Switch Set for Mechanical Keyboard Replacement..",
      "className": "", 
      "img": require("../../imgs/Products/epomaker-nude-rosa.png"),
      "links": { 
         "url":"https://epomaker.com/products/epomaker-nude-rosa-silent-switch-set?sca_ref=6035837.ULc8gdFMXu",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/lJRNG_rPayU?si=tCaEYrw95ssq3gj-&amp;start=332" 
   },
   {
      "id": "zuoya-gmk87",
      "title": "Zuoya GMK87 Kit",
      "desc": "Mechanical keyboard kit hot-swappable Bluetooth 2.4G Wireless RGB Backlit Gasket Structure keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/zuoya-gmk87.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256806181668257.html",
         "amazon": "https://amzn.to/3xNraUz",
         "aliexpress": "https://www.aliexpress.us/item/3256806181668257.html",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/SD-S5tjqFDE?si=2YQU0c2K6rR46M6m" 
   },
   {
      "id": "keebmat-keyboardmat",
      "title": "KEEBMAT Felt Keyboard Mat",
      "desc": "KEEBMAT™ Premium Felt Edition (incl. Free Coaster!)..",
      "className": "", 
      "img": require("../../imgs/Products/keebmat-keyboardmat.png"),
      "links": { 
         "url":"https://keebmat.com/products/keebmat-felt-edition?variant=41449797812373",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/BdN1qqtzL3g" 
   },
   {
      "id": "keebmat-switchbag",
      "title": "KEEBMAT Switch Bags",
      "desc": "Switch Bags - 4 Pack..",
      "className": "", 
      "img": require("../../imgs/Products/keebmat-switchbag.png"),
      "links": { 
         "url":"https://keebmat.com/products/switch-bags-4-pack",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/QkNBmgGPIVY" 
   },
   {
      "id": "keytok-kda-y2k-wave",
      "title": "KeyTok KDA Y2K WAVE",
      "desc": "Keytok KDA Y2K Dye-Sub PBT Keycaps..",
      "className": "", 
      "img": require("../../imgs/Products/keytok-kda-y2k-wave.png"),
      "links": { 
         "url":"https://www.keytok.com/products/kda-y2k?variant=46804680933667?sca_ref=6324503.nnyRHGu9h2",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/hr8jaGAfNI8" 
   },
   {
      "id": "yunzii-yz87",
      "title": "YUNZII YZ87",
      "desc": "75% Gasket Mechanical Keyboard,TKL Wireless Hot Swappable Bluetooth Gaming Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/yunzii-yz87.png"),
      "links": { 
         "url":"https://amzn.to/49hsjAA",
         "amazon": "https://amzn.to/49hsjAA",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/m5HGjyMncQM?si=_43tnjwL2MClXehs" 
   },
   {
      "id": "kiiboom-topaz",
      "title": "KiiBOOM Topaz",
      "desc": "59gf 5-Pin Smooth Linear KiiBOOM Topaz Switches..",
      "className": "", 
      "img": require("../../imgs/Products/kiiboom-topaz.png"),
      "links": { 
         "url":"https://www.kiiboom.com/products/kiiboom-topaz-switches",
         "amazon": "https://amzn.to/43nJsXW",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/kDOVR5aL7tg?si=1hV5PbHnThgw-Bcw&amp;start=169" 
   },
   {
      "id": "blue-yeti",
      "title": "Blue Yeti Mic",
      "desc": "Logitech for Creators Blue Yeti USB Microphone for Gaming, Streaming, Podcasting, Twitch, YouTube..",
      "className": "", 
      "img": require("../../imgs/Products/blue-yeti.png"),
      "links": {
         "url":"https://amzn.to/48KAWU1",
         "amazon": "https://amzn.to/48KAWU1",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/w8L19PDFZSg" 
   },
   {
      "id": "retro-programmer-keycaps",
      "title": "Retro Programmer Keycaps",
      "desc": "Retro Programmer Gray And White 143 Keys Cherry Profile..",
      "className": "", 
      "img": require("../../imgs/Products/retro-programmer-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.com/item/1005006287661752.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/1e120bJevio?si=IBmuA2W2CrJoSsan&amp;start=98" 
   },
   {
      "id": "zuoya-gmk67-s",
      "title": "Zuoya GMK67-S Kit",
      "desc": "Mechanical keyboard kit hot-swappable Bluetooth 2.4G Wireless RGB Backlit Gasket Structure keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/zuoya-gmk67-s.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256806247836983.html",
         "amazon": "https://amzn.to/3Ts6hGO",
         "aliexpress": "https://www.aliexpress.us/item/3256806247836983.html",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/-wKyWjwv8DU?si=W5UsZHFNE-h7IeCF" 
   },
   {
      "id": "soya-keycap",
      "title": "Soya Milk Keycaps",
      "desc": " XDA GMK Soy Milk Keycaps 144 Keys PBT Keycap..",
      "className": "", 
      "img": require("../../imgs/Products/soya-keycap.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805792312994.html",
         "amazon": "https://amzn.to/43dNd20",
         "aliexpress": "https://www.aliexpress.us/item/3256805792312994.html",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/-wKyWjwv8DU?si=bGXqh-fgGc6kadUP&amp;start=134" 
   },
   {
      "id": "gravastar-mercury-m2",
      "title": "Mercury M2 - Use Code SULCE",
      "desc": "Use Code SULCE for 20% off - Gravatar Mercury M2 - Stealth Black..",
      "className": "", 
      "img": require("../../imgs/Products/gravastar-mercury-m2.png"),
      "links": { 
         "url":"https://www.gravastar.com/pages/mercury-gaming-mouse",
         "amazon": "https://amzn.to/4a1306C",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/YDoQ4LD5aWU" 
   },
   {
      "id": "keycap-switch-container",
      "title": "Keycap Switch Container",
      "desc": "Keyboard Switches Container Storage Box..",
      "className": "", 
      "img": require("../../imgs/Products/keycap-switch-container.png"),
      "links": { 
         "url":"https://www.aliexpress.com/item/1005005743555751.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/Wn6QbKD9Cog" 
   },
   {
      "id": "sugar65",
      "title": "WEIKAV Sugar65",
      "desc": "Mechanical Keyboard Aluminum Kits Multifunctional Knob Hot Swap RGB Gaming Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/sugar65.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805852489908.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/X7qzFN6opEY?si=u-BH2OHPw1p5Frre" 
   },
   {
      "id": "programmer-keycaps-2",
      "title": "Programmer Keycaps",
      "desc": "PBT Keycaps 129 Keys Programmer Keycap..",
      "className": "", 
      "img": require("../../imgs/Products/programmer-keycaps-2.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804913139590.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/X7qzFN6opEY?si=A8flR9QuGzaLgcsO&amp;start=65" 
   },
   {
      "id": "lcet-white-raspberry",
      "title": "LCET White Raspberry",
      "desc": "LCET White Raspberry Switch Set for Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/lcet-white-raspberry.png"),
      "links": { 
         "url":"https://amzn.to/42fDSGx",
         "amazon": "https://amzn.to/42fDSGx",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/MJyxsu-mUJ4?si=bNGq_xVGAw7WyToJ&amp;start=136"
   },
   {
      "id": "maono-dm30",
      "title": "Maono DM30 Mic",
      "desc": "MAONO USB Gaming Microphone for PC,  Condenser Mic with RGB Lights..",
      "className": "", 
      "img": require("../../imgs/Products/maono-dm30.png"),
      "links": { 
         "url":"https://www.maono.com/products/dm30-rgb-gaming-usb-internet-microphone?ref=bybxlnnw",
         "amazon": "https://amzn.to/4bb6JQt",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/-ZrCMAvSGHw" 
   },
   {
      "id": "leobog-reaper",
      "title": "LEOBOG Reaper",
      "desc": "LEOBOG Reaper Shaft Switch Set for Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/leobog-reaper.png"),
      "links": { 
         "url":"https://epomaker.com/products/leobog-reaper-switch-set?_pos=1&_psq=leobog+reaper&_ss=e&_v=1.0",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/V7O1bW80TGE?si=ivvaW-1bmXmnkdt8&amp;start=392" 
   },
   {
      "id": "cherry-mx-purple",
      "title": "Glarses x Cherry MX Purple",
      "desc": "THE CHERRY MX PURPLE SWITCH..",
      "className": "", 
      "img": require("../../imgs/Products/cherry-mx-purple.png"),
      "links": { 
         "url":"https://glarses.com/",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/W8f5NsBzMMQ?si=Ldkay8DnXUtozxM-" 
   },
   {
      "id": "gateron-milky-yellow",
      "title": "Gateron Milky Yellow",
      "desc": "Gateron CAP Milky Yellow Pro V2 Mechanical Keyboard 5 Pin Switches Linear Switches 63g..",
      "className": "", 
      "img": require("../../imgs/Products/gateron-milky-yellow.png"),
      "links": { 
         "url":"https://amzn.to/49KB3iY",
         "amazon": "https://amzn.to/49KB3iY",
         "aliexpress": "https://www.aliexpress.us/item/3256803992556353.html",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/qatl2m3vDk4?si=ZwDeE31EM2OOx26m&amp;start=173" 
   },
   {
      "id": "akko-cream-black-v3",
      "title": "Akko V3 Pro Cream Black",
      "desc": "5-Pin Tactile Switch with Dustproof Stem for Mechanical Gaming Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/akko-cream-black-v3.png"),
      "links": { 
         "url":"https://amzn.to/3SJByV5",
         "amazon": "https://amzn.to/3Ve9oSb",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/X7qzFN6opEY?si=0rRtZDhgJt_FCWTA&amp;start=429" 
   },
   {
      "id": "gateron-ef-curry",
      "title": "Gateron EF Curry",
      "desc": "Gateron EF Curry Switches for Mechanical Keyboard 5 pin Linear Swithes Pre-Lubed Factory 50g Keyboard Switch 15mm Spring..",
      "className": "", 
      "img": require("../../imgs/Products/gateron-ef-curry.png"),
      "links": { 
         "url":"https://amzn.to/48fudTk",
         "amazon": "https://amzn.to/48fudTk",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "" 
   },
   {
      "id": "gateron-smoothie",
      "title": "Gateron Smoothie",
      "desc": "GATERON Smoothie Keyboard Switches, Linear RGB LED 5 Pin White 22mm Dual-Stage Spring Factory Pre-lubed 40gf..",
      "className": "", 
      "img": require("../../imgs/Products/gateron-smoothie.png"),
      "links": { 
         "url":"https://amzn.to/3y8Nqbp",
         "amazon": "https://amzn.to/45f54Xw",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/7ZY6YbNN5jA" 
   },
   {
      "id": "kiiboom-sapphire",
      "title": "KiiBOOM Sapphire",
      "desc": "54gf 5-Pin Smooth Linear KiiBOOM Sapphire Switches..",
      "className": "", 
      "img": require("../../imgs/Products/kiiboom-sapphire.png"),
      "links": { 
         "url":"https://www.kiiboom.com/products/kiiboom-sapphire-switches",
         "amazon": "https://amzn.to/3v3PD6L",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/1e120bJevio?si=_3YZ2UhokzMLgdOV&amp;start=127" 
   },
   {
      "id": "leobog-graywood-v3",
      "title": "LEOBOG Graywood v3",
      "desc": "LEOBOG GrayWood V3 Switch Linear Gray Switches..",
      "className": "", 
      "img": require("../../imgs/Products/leobog-graywood-v3.png"),
      "links": { 
         "url":"https://amzn.to/3VhHnZX",
         "amazon": "https://amzn.to/3VhHnZX",
         "aliexpress": "https://www.aliexpress.us/item/3256805705024527.html",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/1jdstqrmUeg?si=Z5te1xiB0PvGfhFX&amp;start=211" 
   },
   {
      "id": "ikea-pegboard",
      "title": "Ikea Skadis Pegboard",
      "desc": "Ikea Skadis Pegboard..",
      "className": "", 
      "img": require("../../imgs/Products/ikea-pegboard.png"),
      "links": { 
         "url":"https://www.ikea.com/us/en/p/skadis-pegboard-white-10321618/",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/SW1glxyW4kw" 
   },
   {
      "id": "ikea-mosslanda",
      "title": "Mosslanda",
      "desc": "ikea mosslanda shelf, picture ledge..",
      "className": "", 
      "img": require("../../imgs/Products/ikea-mosslanda.png"),
      "links": { 
         "url":"https://www.ikea.com/us/en/p/mosslanda-picture-ledge-walnut-effect-80511337/#content",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/6QzVfjmwxW8" 
   },
   {
      "id": "topsky-standing-desk-frame",
      "title": "TOPSKY Standing Computer Desk Frame",
      "desc": "TOPSKY Dual Motor Electric Adjustable Standing Computer Desk for Home and Office (Black Frame only)..",
      "className": "", 
      "img": require("../../imgs/Products/topsky-standing-desk-frame.png"),
      "links": { 
         "url":"https://amzn.to/4aFAR6c",
         "amazon": "https://amzn.to/4aFAR6c",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/Hmvh2wLXzzs?si=uJsscrq7azgXfCmV&amp;start=63" 
   },
   {
      "id": "allen-roth-butcher-block",
      "title": "Butcher Block Acacia Countertop Desk Table",
      "desc": "allen + roth 72-in x 25-in x 1.5-in Natural Straight Butcher Block Acacia Countertop..",
      "className": "", 
      "img": require("../../imgs/Products/allen-roth-butcher-block.png"),
      "links": { 
         "url":"https://www.lowes.com/pd/allen-roth-Acacia-Countertops-72-in-x-25-in-x-1-5-in-Natural-Straight-Butcher-Block-Acacia-Countertop/5013817617",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/Hmvh2wLXzzs?si=ChXxcfwPalefLR2r&amp;start=105" 
   },
   {
      "id": "keytok-back-in-the-game",
      "title": "KeyTok Back in the Game",
      "desc": "Keytok Cherry Back In The Game..",
      "className": "", 
      "img": require("../../imgs/Products/keytok-back-in-the-game.png"),
      "links": { 
         "url":"https://www.keytok.com/products/cherry-back-in-the-game?sca_ref=6324503.nnyRHGu9h2",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/IhV8_3sJ6fE" 
   },
   {
      "id": "kit-lilith",
      "title": "Kit Lilith",
      "desc": "01003 Kit Lilith 60% brick-built keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/kit-lilith.png"),
      "links": { 
         "url":"https://kbdcraft.store/products/lilith?sku=18061199676316487593561690",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/pD5eZtyiou8?si=4ZtmSuEpbCrKJG2i&amp;start=80" 
   },
   {
      "id": "leobog-ice-soul",
      "title": "LEOBOG Ice Soul",
      "desc": "Switch Custom Mechanical Keyboard 5 Pins Tactile Switches 45g Light Force RGB Transparent HIFI Pre-lubed GK61..",
      "className": "", 
      "img": require("../../imgs/Products/leobog-ice-soul.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805629666707.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/pD5eZtyiou8?si=dmtIB4gDNopimCug&amp;start=565" 
   },
   {
      "id": "christmas-keycaps",
      "title": "Christmas Keycaps",
      "desc": "Christmas Eve Original Theme Keycaps Cherry Profile Personalized Keycap For Mechanical Keyboard with 7U and ISO key cap..",
      "className": "", 
      "img": require("../../imgs/Products/christmas-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805991180405.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/pD5eZtyiou8?si=-ybH5ae_doyGpO0q&amp;start=507" 
   },
   {
      "id": "lego-santa",
      "title": "Lego Santa Piece ",
      "desc": "LEGO Series 8 Collectible Minifigure - Santa with Toy Sack..",
      "className": "", 
      "img": require("../../imgs/Products/lego-santa.png"),
      "links": { 
         "url":"https://amzn.to/3NrgTlW",
         "amazon": "https://amzn.to/3NrgTlW",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/pD5eZtyiou8?si=yvWGq_1xwFHxyEyv&amp;start=65" 
   },
   {
      "id": "akko-lavender-purple-v3",
      "title": "Akko V3 Pro Lavender Purple",
      "desc": "5-Pin Tactile Switch with Dustproof Stem for Mechanical Gaming Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/akko-lavender-purple-v3.png"),
      "links": { 
         "url":"https://amzn.to/3R9eUUo",
         "amazon": "https://amzn.to/3R9eUUo",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/-PuzqfnWYlg?si=9f0bWngh_kLO5y2U&amp;start=167" 
   },
   {
      "id": "switch-cracker",
      "title": "Switch Cracker",
      "desc": " Keyboard Switch Opener,2-in-1 CNC Modular Switch Opener for Kailh Gateron Cherry..",
      "className": "", 
      "img": require("../../imgs/Products/switch-cracker.png"),
      "links": { 
         "url":"https://amzn.to/3RdEpE6",
         "amazon": "https://amzn.to/3RdEpE6",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/AJtMeCifxwA" 
   },
   {
      "id": "qk-neo-65",
      "title": "Neo65 Keyboard",
      "desc": "Neo65 is a sleek and minimalistic keyboard with an ultra-slim side bezel, a neat side profile, and a brass weight ..",
      "className": "", 
      "img": require("../../imgs/Products/qk-neo-65.png"),
      "links": { 
         "url":"https://www.qwertykeys.com/products/neo65-1?variant=43882015162611",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/iGqqB6JIPvY?si=w3NJN-F54lApbLw0&amp;start=42" 
   },
   {
      "id": "ktt-halu-halo",
      "title": "KTT HaluHalo V2",
      "desc": "KTT HaluHalo V2 Linear Switches..",
      "className": "", 
      "img": require("../../imgs/Products/ktt-halu-halo.png"),
      "links": { 
         "url":"https://dangkeebs.com/products/ktt-haluhalo-v2",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/iGqqB6JIPvY?si=w3NJN-F54lApbLw0&amp;start=409" 
   },
   {
      "id": "gummy-xda-keycaps",
      "title": "Gummy Bears PBT Keycaps",
      "desc": "DA Profile Color Cute XDA Key Cap for MX Switche Custom Mechanical Gaming Keyboard Caps..",
      "className": "", 
      "img": require("../../imgs/Products/gummy-xda-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804831313643.html",
         "amazon": "https://amzn.to/49NjYFW",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/iGqqB6JIPvY?si=w3NJN-F54lApbLw0&amp;start=293" 
   },
   {
      "id": "leobog-graywood-v4",
      "title": "LEOBOG Graywood v4",
      "desc": "LEOBOG GrayWood V4 Switch Linear Gray Switches..",
      "className": "", 
      "img": require("../../imgs/Products/leobog-graywood-v4.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805705024527.html",
         "amazon": "https://amzn.to/3Tv0S0z",
         "aliexpress": "https://www.aliexpress.us/item/3256805705024527.html",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/1jdstqrmUeg?si=FlkTg9g2n17GUfkR&amp;start=231" 
   },
   {
      "id": "gravastar-alpha65",
      "title": "GravaStar ALPHA65",
      "desc": "ALPHA65 FAST WALL CHARGER (GAN 65W)..",
      "className": "", 
      "img": require("../../imgs/Products/gravastar-alpha65.png"),
      "links": { 
         "url":"https://www.gravastar.com/products/alpha65-gan-fast-charger?variant=43696240099573",
         "amazon": "https://amzn.to/3v3GISW",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/eusGtdH5v-A" 
   },
   {
      "id": "monsgeek-mg108w",
      "title": "Akko MONSGEEK MG108W",
      "desc": "Hot-Swap Mechanical Gaming Keyboard Kit Wired USB Type-C Wireless 2.4GHz..",
      "className": "", 
      "img": require("../../imgs/Products/monsgeek-mg108w.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804894737143.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/Dy1dq1g90do?si=0V51yWjQGehIzzTw&amp;start=49" 
   },
   {
      "id": "akko-cream-yellow-v3",
      "title": "Akko V3 Pro Cream Yellow",
      "desc": "Keyboard Switch 5 Pin 50gf Linear Switch with Dustproof Stem..",
      "className": "", 
      "img": require("../../imgs/Products/akko-cream-yellow-v3.png"),
      "links": { 
         "url":"https://amzn.to/3UsEuae",
         "amazon": "https://amzn.to/3UsEuae",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/H7dBHPoiisQ?si=L904FxPfWytZPrtw&amp;start=288" 
   },
   {
      "id": "maono-dgm20",
      "title": "Maono DGM20 Gaming Mic",
      "desc": "MAONO USB Gaming Microphone for PC, Noise Cancellation Condenser Mic with RGB Lights..",
      "className": "", 
      "img": require("../../imgs/Products/maono-dgm20.png"),
      "links": { 
         "url":"https://www.maono.com/products/dgm20-gamerwave-condenser-usb-gaming-rgb-internet-microphone?ref=bybxlnnw",
         "amazon": "https://amzn.to/3shedQh",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/JMClVggyveg" 
   },
   {
      "id": "navy-sa-keycaps",
      "title": "Navy Blue SA Keycaps",
      "desc": "New Arrival SA profile Double shot keycaps ABS Flamingo By the Sea Godspeed GMK theme..",
      "className": "", 
      "img": require("../../imgs/Products/navy-sa-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805196088495.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/wx7oEZta6oY" 
   },
   {
      "id": "etenwolf-ball-pump",
      "title": "Etenwolf Ball Pump",
      "desc": "Ball Pump Inflation & Deflation 2-17PSI, Pump with Precise Pressure Gauge, Rechargeable Air Pump for Balls..",
      "className": "", 
      "img": require("../../imgs/Products/etenwolf-ball-pump.png"),
      "links": { 
         "url":"https://amzn.to/47gV3sP",
         "amazon": "https://amzn.to/47gV3sP",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/HRadPBe5VLM" 
   },
   {
      "id": "crystal-pink-keycaps",
      "title": "XVX Crystal Pink Keycaps",
      "desc": "Jelly Round Side Keycaps Ice Crystal Translucent Pink OEM Profile Key cap..",
      "className": "", 
      "img": require("../../imgs/Products/crystal-pink-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805014002174.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/kGk1tNlxRA0?si=rnpHxNqOOPM59V4V&amp;start=202" 
   },
   {
      "id": "ro-candy-pink",
      "title": "Rust-Oleum Candy Pink",
      "desc": "Painter's Touch 2X Ultra Cover Spray Paint, 12 oz, Satin Moss Green..",
      "className": "", 
      "img": require("../../imgs/Products/ro-candy-pink.png"),
      "links": { 
         "url":"https://amzn.to/49fkblr",
         "amazon": "https://amzn.to/49fkblr",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/kGk1tNlxRA0?si=z4U-BVvIKKQJ5B7L&amp;start=120" 
   },
   {
      "id": "outemu-silent-cream-yellow",
      "title": "Outemu Silent Cream Yellow",
      "desc": "Outemu Switches Mechanical Keyboard Switch 5Pin Silent Tactile..",
      "className": "", 
      "img": require("../../imgs/Products/outemu-silent-cream-yellow.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804483625584.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/kGk1tNlxRA0?si=2EPEeJoLh9WVR9-l&amp;start=218" 
   },
   {
      "id": "flower-keycap",
      "title": "Flower Epoxy Keycap",
      "desc": "Epoxy DIY Translucent Leaves Dry Flowers Paper Cross Axis Mechanical Keyboard Keycap Universal Accessories..",
      "className": "", 
      "img": require("../../imgs/Products/flower-keycap.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805613790140.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/kGk1tNlxRA0?si=rnpHxNqOOPM59V4V&amp;start=58" 
   },
   {
      "id": "gold-knob",
      "title": "Gold Mechanical Keyboard Knob",
      "desc": "20mm Volume Control Gear Manual Rotary Key Cap For Fine Tuning Metal Texture Inner Diameter 6mm..",
      "className": "", 
      "img": require("../../imgs/Products/gold-knob.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805686324592.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/kGk1tNlxRA0?si=rnpHxNqOOPM59V4V&amp;start=63" 
   },
   {
      "id": "matcha-keycaps",
      "title": "Matcha Themed XDA Keycaps",
      "desc": "PBT Keycap XDA Highly Sublimated Technology Personalized Key Caps For Cherry MX Switch..",
      "className": "", 
      "img": require("../../imgs/Products/matcha-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805545806691.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/HFpT1lTBLz0"
   },
   {
      "id": "matcha-green",
      "title": "Matcha Green Linear Switch",
      "desc": "3 Pin Matcha Green Linear Keyboard Switch 45g Mechanical keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/matcha-green.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804128821396.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/ImyYYqIfwjA?si=7In8K030cEjGnJ5n&amp;start=248" 
   },
   {
      "id": "ro-satin-moss-green",
      "title": "Rust-Oleum Satin Moss Green",
      "desc": "Painter's Touch 2X Ultra Cover Spray Paint, 12 oz, Satin Moss Green..",
      "className": "", 
      "img": require("../../imgs/Products/ro-satin-moss-green.png"),
      "links": { 
         "url":"https://amzn.to/45ZZ8QW",
         "amazon": "https://amzn.to/45ZZ8QW",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/ImyYYqIfwjA?si=3FYzu7sBk5-R3LlY&amp;start=106" 
   },
   {
      "id": "long-strip-display",
      "title": "Case Screen - Long Strip Display",
      "desc": "8.8 Inch Long Strip Display 1920x480 Stretched Bar IPS LCD Panel..",
      "className": "", 
      "img": require("../../imgs/Products/long-strip-display.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804882923842.html?spm=a2g0o.order_list.order_list_main.4.780c1802KYHX3E",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/QGRyERLMR1o" 
   },
   {
      "id": "gamemax-spark",
      "title": "GAMEMAX Spark",
      "desc": "Micro-ATX Tower Computer Case with Removable Dust-Proof Filter, Dual Tempered Glass Side Panels..",
      "className": "", 
      "img": require("../../imgs/Products/gamemax-spark.png"),
      "links": { 
         "url":"https://amzn.to/46VhdAS",
         "amazon": "https://amzn.to/46VhdAS",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/lAuoFRxsz-E?si=7kdhDHS_6R_23PdG&amp;start=308"
   },
   {
      "id": "ws-aurora-fog",
      "title": "WS Aurora Fog",
      "desc": "Aurora Series Fog Linear Switch..",
      "className": "", 
      "img": require("../../imgs/Products/ws-aurora-fog.png"),
      "links": { 
         "url":"https://cannonkeys.com/products/aurora-series-fog-linear-switch?variant=40912132571247",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/49w5GYQPAzU?si=vT498dShr1uD3Bte&amp;start=147" 
   },
   {
      "id": "glorious-gmmk-pro",
      "title": "Glorious GMMK Pro",
      "desc": "Award-winning modular mechanical keyboard with enthusiast component..",
      "className": "", 
      "img": require("../../imgs/Products/glorious-gmmk-pro.png"),
      "links": { 
         "url":"https://amzn.to/3s6rmfb",
         "amazon": "https://amzn.to/3s6rmfb",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/E7lJZbxDvEw?si=WmRgwwAPSVyu6Pex" 
   },
   {
      "id": "xvx-bw-keycaps",
      "title": "XVX Black and White Keycaps",
      "desc": "Double Shot PBT Keycap Custom WOB Keycap Cherry Profile..",
      "className": "", 
      "img": require("../../imgs/Products/xvx-bw-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804826524481.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/E7lJZbxDvEw?si=now5fsHRqOoXFmq1&amp;start=243" 
   },
   {
      "id": "highground-opal-base65-silent-glacier",
      "title": "Highground OPAL Base 65 Keyboard - Silent Glacier Switch",
      "desc": "65% keyboard (dedicated arrow keys) with ABS keycaps. Features hot-swappable switches, aluminum plate, and silicone dampening foam. ..",
      "className": "", 
      "img": require("../../imgs/Products/highground-opal-base65-silent-glacier.png"),
      "links": { 
         "url":"https://higround.co/products/opal-base-65-keyboard-silent-glacier-switch",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/424fRFWHiX0?si=t7eBJskSfNegoW-l" 
   },
   {
      "id": "coral-mda-keycaps",
      "title": "Coral MDA Keycaps",
      "desc": "133 Keys GMK MDA Profile Keycap Coral..",
      "className": "", 
      "img": require("../../imgs/Products/coral-mda-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805435939310.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/1OQJiBIRjSo?si=vePKMiMy8lEVDkox&amp;start=177" 
   },
   {
      "id": "outemu-silent-peach",
      "title": "Outemu Silent Peach",
      "desc": "Outemu Switches Mechanical Keyboard Switch 3Pin Silent..",
      "className": "", 
      "img": require("../../imgs/Products/outemu-silent-peach.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256803705622852.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/1OQJiBIRjSo?si=Kd2aH5YS49GccG7t&amp;start=229" 
   },
   {
      "id": "aukey-webcam",
      "title": "AUKEY 1080p Webcam ",
      "desc": "AUKEY 1080P Webcam w/ Dual Noise Reduction Stereo Microphones - Black PC-LM1E..",
      "className": "", 
      "img": require("../../imgs/Products/aukey-webcam.png"),
      "links": { 
         "url":"https://www.newegg.com/aukey-pc-lm1e-overview-full-hd-video-1080p-webcam/p/1EF-01DY-00003",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, other", 
      "preview": "https://www.youtube.com/embed/8Ldi7dgOEZY" 
   },
   {
      "id": "kemove-tmkb",
      "title": "KEMOVE TMKB Gaming Mouse",
      "desc": "TMKB Falcon M1SE Ultralight Honeycomb Gaming Mouse, High-Precision 12800DPI Optical Sensor..",
      "className": "", 
      "img": require("../../imgs/Products/kemove-tkmb.png"),
      "links": { 
         "url":"https://amzn.to/3FGRdxm",
         "amazon": "https://amzn.to/3FGRdxm",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/mZN_mWatPKo" 
   },
   {
      "id": "braidol-wireless-display-adapter",
      "title": "Braidol Wireless Display Adapter",
      "desc": "Wireless HDMI Display Dongle Adapter 4K..",
      "className": "", 
      "img": require("../../imgs/Products/braidol-wireless-display-adapter.png"),
      "links": { 
         "url":"https://amzn.to/3s6rnQh",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/Ydo971fEVJo"
   },
   {
      "id": "wandair-air-duster",
      "title": "WANDAIR Air Duster",
      "desc": "Electric Air Duster Compressor, Cordless Electronics Cleaner..",
      "className": "", 
      "img": require("../../imgs/Products/wandair-air-duster.png"),
      "links": { 
         "url":"https://amzn.to/47bThJb",
         "amazon": "https://amzn.to/47bThJb",
         "aliexpress": "",
      },
      "type": "all", 
      "preview": "https://www.youtube.com/embed/KJowCQP1xyk" 
   },
   {
      "id": "kemove-p10",
      "title": "KEMOVE P10 2-in-1 Switch Puller",
      "desc": "Switch Puller Keycap Puller for Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/kemove-p10.png"),
      "links": { 
         "url":"https://amzn.to/49foWLM",
         "amazon": "https://amzn.to/49foWLM",
         "aliexpress": "",
      },
      "type": "all, switch, keycaps", 
      "preview": "https://www.youtube.com/embed/i7maAEcJRkA" 
   },
   {
      "id": "tester-68",
      "title": "TESTER68 Mechanical Keyboard",
      "desc": "Gasket Kit RGB Backlit Type-C Swappable Switch Bluetooth 2.4G Wireless 3 Mode Customized Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/tester-68.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805275948120.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/qatl2m3vDk4?si=AT81F_fW38ltT13w" 
   },
   {
      "id": "npet-k621",
      "title": "NPET K621 Gaming Keyboard",
      "desc": "60% Gaming Keyboard, Triple Mode 2.4G/BT5.0/USB-C RGB Backlit Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/npet-k621.png"),
      "links": { 
         "url":"https://amzn.to/40mPIh2",
         "amazon": "https://amzn.to/40mPIh2",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/BrfFPqcmBw8" 
   },
   {
      "id": "zuoya-gmk67",
      "title": "Zuoya GMK67 Kit",
      "desc": "Mechanical keyboard kit hot-swappable Bluetooth 2.4G Wireless RGB Backlit Gasket Structure keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/zuoya-gmk67.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805593846179.html",
         "amazon": "https://amzn.to/3Tt7U6V",
         "aliexpress": "",
      },
      "type": "all, keyboard", 
      "preview": "https://www.youtube.com/embed/UDXSWhzkeas?si=hcmKs-7mwCxJ_Vfd" 
   },
   {
      "id": "ktt-kang-white",
      "title": "KTT Kang White",
      "desc": "KTT Kang White Switches V3 for Mechanical Keyboard Linear 43g 3 Pins..",
      "className": "", 
      "img": require("../../imgs/Products/ktt-kang-white.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256803847820546.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/b3vEeP6OmK8" 
   },
   {
      "id": "huano-pineapple",
      "title": "Huano Pineapple",
      "desc": "HUANO Pineapple Light Pressure Linear 5 Pin Switches Dustproof..",
      "className": "", 
      "img": require("../../imgs/Products/huano-pineapple.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805141467819.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/qatl2m3vDk4?si=HkSft2CZMBrUWlnk&amp;start=143" 
   },
   {
      "id": "permatex-dielectric-grease",
      "title": "Stabilizer Lube",
      "desc": "For Lubing Metal Piece of Stabilizer",
      "className": "", 
      "img": require("../../imgs/Products/permatex-dielectric-grease.png"),
      "links": { 
         "url":"https://amzn.to/46xQ3PH",
         "amazon": "https://amzn.to/46xQ3PH",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/iGqqB6JIPvY?si=-byTeI-Jssf9h3tt&amp;start=159" 
   },
   {
      "id": "gpl-205G0",
      "title": "Switch Lube + Oil (AliExpress)",
      "desc": "For Lubing Switches + Stabilizer Switch + Oil for springs",
      "className": "", 
      "img": require("../../imgs/Products/gpl-205G0.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256803177424912.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keyboard, switch", 
      "preview": "https://www.youtube.com/embed/X24pMAu2AlM?si=gK9VFU3QmdkPgAkF&amp;start=57" 
   },
   {
      "id": "krytox-gpl-205G0",
      "title": "Switch Lube (Amazon)",
      "desc": "For Lubing Switches + Stabilizer Switch",
      "className": "", 
      "img": require("../../imgs/Products/krytox-gpl-205G0.png"),
      "links": { 
         "url":"https://amzn.to/47KYbOl",
         "amazon": "https://amzn.to/47KYbOl",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/iGqqB6JIPvY?si=-byTeI-Jssf9h3tt&amp;start=159" 
   },
   {
      "id": "holy-panda",
      "title": "Holy Panda",
      "desc": "Holy Panda Tactile Keyboard Switch 3 Pin..",
      "className": "", 
      "img": require("../../imgs/Products/holy-panda.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804202248944.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/4uZcaXIZh3U?si=CeKggSBVB6aN5g7H&amp;start=165" 
   },
   {
      "id": "holy-panda-v2",
      "title": "Holy Panda V2",
      "desc": "Tactile Switch, POM Mechanical Keyboard Custom DIY 3Pins Switches..",
      "className": "", 
      "img": require("../../imgs/Products/holy-panda-v2.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804605951489.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "https://www.youtube.com/embed/4uZcaXIZh3U?si=OgqSs6zqPXuqZl_k&amp;start=206" 
   },
   {
      "id": "minimalist-white-keycaps",
      "title": "Minimalist White Keycaps",
      "desc": "125 Keys Minimalist White Japanese Keycaps For Mechanical Keyboard Cherry Profile Dye..",
      "className": "", 
      "img": require("../../imgs/Products/minimalist-white.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804466857516.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/IWlOe9qRsy4?si=ypO3f9PDSabUWeNM" 
   },
   {
      "id": "programmer-keycaps",
      "title": "Programmer Keycaps",
      "desc": "ZUOYA Dye sublimation personalized keycaps Cherry high Keycaps..",
      "className": "", 
      "img": require("../../imgs/Products/programmer-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256805445389010.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/H7dBHPoiisQ?si=1HWVx9W3l9zFvNrm&amp;start=105" 
   },
   {
      "id": "everglide-stabilizer",
      "title": "Everglide Screw-In Stabilizer",
      "desc": "Everglide Transparent Gold Plated Pcb screw in Stabilizer for Custom Mechanical Keyboard..",
      "className": "", 
      "img": require("../../imgs/Products/everglide-stabilizer.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/2255800082366128.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, switch", 
      "preview": "" 
   },
   {
      "id": "plastic-xda-keycaps",
      "title": "Plastic Themed XDA Keycaps",
      "desc": "PBT Keycaps XDA Profile Personalized English Russian Keycap Dye Sublimation..",
      "className": "", 
      "img": require("../../imgs/Products/plastic-xda-keycaps.png"),
      "links": { 
         "url":"https://www.aliexpress.us/item/3256804934447950.html",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, keycaps", 
      "preview": "https://www.youtube.com/embed/W_y8YDIEoMg?si=0fOOD0uvrT-pm1bH&amp;start=27" 
   },
   {
      "id": "nb-monitor-mount-f80",
      "title": "NB North Bayou Monitor F80",
      "desc": "Desk Mount Stand Full Motion Swivel Monitor Arm with Gas Spring for 17-30''Computer Monitors..",
      "className": "", 
      "img": require("../../imgs/Products/nb-monitor-mount-f80.png"),
      "links": { 
         "url":"https://amzn.to/46GpB6E",
         "amazon": "https://amzn.to/46GpB6E",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/xBwmk1GzQ5Q" 
   },
   {
      "id": "gigabyte-m28u",
      "title": "GIGABYTE M28U",
      "desc": "144Hz Gaming Monitor, 3840 x 2160 SS IPS Display, 2ms (MPRT) Response Time, 94% DCI-P3, VESA Display HDR400..",
      "className": "", 
      "img": require("../../imgs/Products/gigabyte-m28u.png"),
      "links": { 
         "url":"https://amzn.to/3SlwUx1",
         "amazon": "https://amzn.to/3SlwUx1",
         "aliexpress": "",
      },
      "type": "all, monitor, setup", 
      "preview": "https://www.youtube.com/embed/x97oaiZjliE?si=JKlivddjjQYBSzvd" 
   },
   {
      "id": "govee-lightbulbs",
      "title": "Govee Smart Light Bulbs",
      "desc": "WiFi Bluetooth Color Changing Light Bulbs, Music Sync, 54 Dynamic Scenes..",
      "className": "", 
      "img": require("../../imgs/Products/govee-lightbulbs.png"),
      "links": { 
         "url":"https://amzn.to/47e2ctH",
         "amazon": "https://amzn.to/47e2ctH",
         "aliexpress": "",
      },
      "type": "all, lights, setup", 
      "preview": "" 
   },
   {
      "id": "govee-lights",
      "title": "Govee DreamView G1",
      "desc": "Gaming Lights | Neon Monitor Backlights..",
      "className": "", 
      "img": require("../../imgs/Products/govee-dreamview.png"),
      "links": { 
         "url":"https://us.govee.com/products/govee-dreamview-g1-gaming-light-for-24-32-pcs",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, lights, setup", 
      "preview": "https://www.youtube.com/embed/BOnlpw5hw6Y" 
   },
   {
      "id": "monster-lights",
      "title": "Monster Light Bar Pair",
      "desc": "LED Monitor Backlights..",
      "className": "", 
      "img": require("../../imgs/Products/monster-lights.png"),
      "links": { 
         "url":"https://www.walmart.com/ip/Monster-LED-2-Pack-Multi-Color-Light-Bar-with-Multi-Position-Base-with-Remote-All-Occasion-Office/662755441",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, lights", 
      "preview": "https://www.youtube.com/embed/EyKHwBTky0E"
   },
   {
      "id": "cable-tray",
      "title": "Cable Tray",
      "desc": "Under Desk Cable Management Tray, Metal Cable Tray Basket for Wire Management..",
      "className": "", 
      "img": require("../../imgs/Products/cable-tray.png"),
      "links": { 
         "url":"https://amzn.to/3QeNQm2",
         "amazon": "https://amzn.to/3QeNQm2",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/-2bhadMWdLE?si=V2Zk_vElaQW7NWYf&amp;start=49" 
   },
   {
      "id": "presonus-eris",
      "title": "PreSonus Eris E3.5-3.5",
      "desc": "Near Field Studio Monitors (Pair) – Powered Desktop Speakers for Music Production..",
      "className": "", 
      "img": require("../../imgs/Products/presonus-eris.png"),
      "links": { 
         "url":"https://amzn.to/3QGpVNW",
         "amazon": "https://amzn.to/3QGpVNW",
         "aliexpress": "",
      },
      "type": "all, setup, other", 
      "preview": "" 
   },
   {
      "id": "cable-management",
      "title": "Cable Management",
      "desc": "Cord Management Organizer Kit..",
      "className": "", 
      "img": require("../../imgs/Products/cable-management.png"),
      "links": { 
         "url":"https://amzn.to/3QDbZ7h",
         "amazon": "https://amzn.to/3QDbZ7h",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/-2bhadMWdLE?si=V2Zk_vElaQW7NWYf&amp;start=60" 
   },
   {
      "id": "plug-extension",
      "title": "23-1 Plug Extension",
      "desc": "Power Strip 23 in 1, 20 Outlets Surge Protector Wall Mount with 2 USB Ports + 1 USB C Port..",
      "className": "", 
      "img": require("../../imgs/Products/plug-extension.png"),
      "links": { 
         "url":"https://amzn.to/3tSvlMJ",
         "amazon": "https://amzn.to/3tSvlMJ",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/-2bhadMWdLE?si=V2Zk_vElaQW7NWYf&amp;start=58" 
   },
   {
      "id": "pc-build",
      "title": "PC Build",
      "desc": "My Custom PC Build, parts list is in the description..",
      "className": "", 
      "img": require("../../imgs/Products/pc-build.png"),
      "links": { 
         "url":"https://youtu.be/lAuoFRxsz-E?si=g-fyL0gJ3BA9yqkO",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, setup", 
      "preview": "https://www.youtube.com/embed/lAuoFRxsz-E?si=l2W1xU-UEEuqlFUt" 
   },
   {
      "id": "sugar65-software",
      "title": "WEIKAV Sugar65 Software",
      "desc": "WEIKAV Sugar65 Software..",
      "className": "", 
      "img": require("../../imgs/Products/sugar65.png"),
      "links": { 
         "url":"https://weikav.com/waikv-software/",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "" 
   },
   {
      "id": "fooocus",
      "title": "Fooocus Ai Image Generator",
      "desc": "Fooocus is an image generating software (based on Gradio)..",
      "className": "", 
      "img": require("../../imgs/Products/fooocus.png"),
      "links": { 
         "url":"https://github.com/lllyasviel/Fooocus",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://www.youtube.com/embed/0fZaShczzzo" 
   },
   {
      "id": "glorious-core",
      "title": "Glorious Core",
      "desc": "GMMK software: Glorious CORE is a unified software suite designed to give you full control over your peripherals..",
      "className": "", 
      "img": require("../../imgs/Products/glorious-core.png"),
      "links": { 
         "url":"https://www.gloriousgaming.com/pages/glorious-core?core=GMMK%20PRO",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://www.youtube.com/embed/E7lJZbxDvEw?si=xJUC2ZR3VLSC_WOr&amp;start=268" 
   },
   {
      "id": "zuoya-gmk67-software",
      "title": "Zuoya GMK67 Software",
      "desc": "Zuoya GMK67 Software..",
      "className": "", 
      "img": require("../../imgs/Products/zuoya-gmk67-software.png"),
      "links": { 
         "url":"https://files.inbox.lv/shared/file/5f0e7efdc56e8ad317eeecd5f511b4df374ba0be",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://www.youtube.com/watch?v=MLq9_GpfeUM&ab_channel=SULCE" 
   },
   {
      "id": "higround-base65-software",
      "title": "HIGROUND Base 65 Software",
      "desc": "HIGROUND Base 65 Software..",
      "className": "", 
      "img": require("../../imgs/Products/higround-base65-software.png"),
      "links": { 
         "url":"https://higround.co/pages/basecamp",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://www.youtube.com/embed/424fRFWHiX0?si=IDyFhRF-2CjW3tjG&amp;start=208" 
   },
   {
      "id": "aida64-software",
      "title": "AIDA64",
      "desc": "Sensor Panel Screen Software..",
      "className": "", 
      "img": require("../../imgs/Products/aida64.png"),
      "links": { 
         "url":"https://www.aida64.com/downloads/YmQ1ZDlhMjQ=?utm_source=aida64&utm_medium=update&utm_campaign=stableproduct",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://www.youtube.com/embed/JeeOHwnhNLk?si=-oCQdqDfhUFSRrTz&amp;start=165" 
   },
   {
      "id": "aula-f75-software",
      "title": "Aula F75 Software",
      "desc": "Aula F75 Software..",
      "className": "", 
      "img": require("../../imgs/Products/aula-f75-software.png"),
      "links": { 
         "url":"https://epomaker.com/blogs/software/epomaker-x-aula-f75-driver",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software", 
      "preview": "https://youtu.be/n1ikxHv2j2E?si=AaCRPvkiUGHfimXC" 
   },
]